import React from 'react';

import {Button} from '@macmillanplatform/ui-core';

import './index.css';

const SnapButton = props => {
  const {isDisabled, onClickFunc, isLoading, iconImage, btnText, outlined} =
    props;
  return (
    <Button
      disabled={isDisabled}
      className="SnapButton"
      onClick={onClickFunc}
      outlined={outlined}
    >
      {isLoading ? (
        <i className="fa-duotone fa-spinner-scale fa-spin"></i>
      ) : (
        <>
          <img src={iconImage} alt="folio icon" /> {btnText}
        </>
      )}
    </Button>
  );
};

export default SnapButton;
