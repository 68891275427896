import React from 'react';

import menuIcon from '../../assets/ellipsis-vertical-regular.svg';
import shareApple from '../../assets/share-apple_1.svg';
import infoIcon from '../../assets/square-info-solid.svg';
import addApple from '../../assets/square-plus-light.svg';

import './index.css';

function getOS() {
  let userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (iosPlatforms.indexOf(platform) !== -1) return 'iOS';

  if (/Android/.test(userAgent)) return 'Android';
}

const IOSInstructions = () => {
  return (
    <ol>
      <li>
        Tap the share icon <img src={shareApple} alt="Share icon" />
      </li>
      <li>
        Choose “Add to Home Screen” <img src={addApple} alt="Add icon" />
      </li>
      <li>Tap “Add”</li>
    </ol>
  );
};
const AndroidInstructions = () => {
  return (
    <ol>
      <li>
        Tap the menu &ldquo;
        <img src={menuIcon} alt="Menu icon" />
        &rdquo; icon
      </li>
      <li>Select “Install App” or “Add to Home Screen”</li>
      <li>Confirm by tapping “Add” or “Add to Home”</li>
    </ol>
  );
};

export const INSTRUCTIONS_MAP = new Map([
  ['Android', AndroidInstructions()],
  ['iOS', IOSInstructions()],
]);

const ShortcutInfo = () => {
  let device = getOS();
  if (!device) return;
  return (
    <div className="ShortcutInfo">
      <div className="ShortcutInfo-Container">
        <span>
          <img src={infoIcon} alt="Info icon" /> Add Snap Shortcut to your Home
          Screen:
        </span>
        {INSTRUCTIONS_MAP.get(device)}
      </div>
    </div>
  );
};

export default ShortcutInfo;
