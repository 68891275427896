// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SnapButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2.625rem;
  padding: 1rem 0.5rem;
  margin-top: 1rem;
  border-radius: 1.5rem;
  letter-spacing: -0.01rem;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

.SnapButton span {
  display: contents;
}
.SnapButton:disabled i {
  font-size: 2.625rem;
}

.SnapButton:disabled {
  background-color: color-mix(in srgb, var(--primary) 80%, transparent);
  color: #fff;
}

.SnapButton img {
  display: block;
  width: 2.25rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/photo/components/snap-button/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,oBAAoB;EACpB,gBAAgB;EAChB,qBAAqB;EACrB,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,qEAAqE;EACrE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,cAAc;EACd,cAAc;EACd,oBAAoB;AACtB","sourcesContent":[".SnapButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  font-size: 1.5rem;\n  line-height: 2.625rem;\n  padding: 1rem 0.5rem;\n  margin-top: 1rem;\n  border-radius: 1.5rem;\n  letter-spacing: -0.01rem;\n  margin-bottom: 1rem;\n  position: relative;\n  overflow: hidden;\n}\n\n.SnapButton span {\n  display: contents;\n}\n.SnapButton:disabled i {\n  font-size: 2.625rem;\n}\n\n.SnapButton:disabled {\n  background-color: color-mix(in srgb, var(--primary) 80%, transparent);\n  color: #fff;\n}\n\n.SnapButton img {\n  display: block;\n  width: 2.25rem;\n  height: 1.5rem;\n  margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
