// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShortcutInfo {
  display: inline-flex;
  background-color: #fff;
  padding: 0.75rem 1rem 0.25rem;
  margin: 0 auto;
  width: 100%;
  color: var(--primary);
}
.ShortcutInfo-Container {
  margin: auto;
}
.ShortcutInfo span {
  font-weight: bold;
  display: inline-block;
  width: 21rem;
}
.ShortcutInfo span img {
  max-height: 1.25rem;
  max-width: 1.25rem;
  margin: auto 0.25rem -0.2rem 0;
}
.ShortcutInfo ol {
  line-height: 1.5rem;
  display: block;
  text-align: left;
  margin: 0.5rem auto;
}
.ShortcutInfo img {
  position: relative;
  max-height: 1rem;
  max-width: 1rem;
  margin: auto 0.15rem -0.075rem;
  display: inline-block;
  filter: invert(14%) sepia(48%) saturate(5131%) hue-rotate(328deg)
    brightness(95%) contrast(117%);
}

@media screen and (max-width: 385px) {
  .ShortcutInfo span {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/title/components/shortcut-info/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,6BAA6B;EAC7B,cAAc;EACd,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;AAChC;AACA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,8BAA8B;EAC9B,qBAAqB;EACrB;kCACgC;AAClC;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".ShortcutInfo {\n  display: inline-flex;\n  background-color: #fff;\n  padding: 0.75rem 1rem 0.25rem;\n  margin: 0 auto;\n  width: 100%;\n  color: var(--primary);\n}\n.ShortcutInfo-Container {\n  margin: auto;\n}\n.ShortcutInfo span {\n  font-weight: bold;\n  display: inline-block;\n  width: 21rem;\n}\n.ShortcutInfo span img {\n  max-height: 1.25rem;\n  max-width: 1.25rem;\n  margin: auto 0.25rem -0.2rem 0;\n}\n.ShortcutInfo ol {\n  line-height: 1.5rem;\n  display: block;\n  text-align: left;\n  margin: 0.5rem auto;\n}\n.ShortcutInfo img {\n  position: relative;\n  max-height: 1rem;\n  max-width: 1rem;\n  margin: auto 0.15rem -0.075rem;\n  display: inline-block;\n  filter: invert(14%) sepia(48%) saturate(5131%) hue-rotate(328deg)\n    brightness(95%) contrast(117%);\n}\n\n@media screen and (max-width: 385px) {\n  .ShortcutInfo span {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
