import React from 'react';

import {Button, Input} from '@macmillanplatform/ui-core';

import {COUNTRY_LIST} from '../../constants';

import macmillanLogo from '../../assets/mac-logo.svg';

import SnapLogo from '../../components/snap-logo';
import ShortcutInfo from './components/shortcut-info';

import './index.css';

const TitleScreen = ({
  email,
  setEmail,
  saveEmail,
  country,
  setCountry,
  device,
}) => {
  return (
    <section className="TitleScreen">
      <div className="TitleScreen--main">
        <div className="TitleScreen--snap-logo">
          <SnapLogo large={true} />
        </div>
        <div className="TitleScreen--select input-outlined">
          <select
            name="country"
            onChange={e => setCountry(e.target.value)}
            defaultValue={country}
            data-testid="select-input"
          >
            {Object.keys(COUNTRY_LIST).map(key => (
              <option key={key} value={key}>
                {COUNTRY_LIST[key]}
              </option>
            ))}
          </select>
        </div>
        <Input
          className="TitleScreen--button TitleScreen--email-input"
          placeholder={'Enter your Macmillan Email Address*'}
          outlined
          value={email}
          onChange={e => setEmail(e.target.value)}
          data-testid="email-input"
        />
        <Button
          className="TitleScreen--button"
          type={'button'}
          onClick={saveEmail}
        >
          Save and Continue
        </Button>
        <p className="TitleScreen--footnote">
          *Only Macmillan email addresses are allowed at this time.
        </p>
        <ShortcutInfo device={device} />
      </div>
      <aside className="TitleScreen--owner-mark">
        <img
          className="TitleScreen--macmillan-logo"
          src={macmillanLogo}
          alt="Macmillan Logo"
        />
        <p>
          <span className="TitleScreen--copyright">&copy; </span>2024
        </p>
      </aside>
    </section>
  );
};

export default React.memo(TitleScreen);
